import {QuestionSvg} from '/components/shared/SvgIcons'

export default function HelpButton() {

    return (
        <a href="/faq" className='hidden 2xl:block'>
            <div className="bg-gold rounded-full flex justify-center items-center cursor-pointer px-4">
                {QuestionSvg()}
                <div className="font-cooper-hewitt-semibold text-white uppercase text-base px-2 pt-2 pb-1">
                  Hilfe &amp; FAQ
                </div>
            </div>
        </a>
    );
}
