import Link from "next/link";
import { useContext, useState } from "react";
import {useRouter} from "next/router";
import NavItem from "./NavItem";
import {MORE_NAVIGATION, NAVIGATION} from "/components/shared/constants";
import Logo from "/components/shared/Logo";
import CodeEinlosen from "/components/shared/buttons/CodeEinlosen";
import AppContext from "/context/AppContext";
import RunningShowContainer from "/components/shared/RunningShowContainer";
import DevDateSetter from "./DevDateSetter";
import {SvgTriangleIconDown} from "../SvgIcons";

export default function NavBarDesktop() {
    const router = useRouter();
    const {activeShow, setActiveShow, agendaVisible, setAgendaVisible} = useContext(AppContext);
    const [moreVisible, setMoreVisible] = useState(false);


    const logo = (
        <Logo
          name="Junges Theater Bonn"
          width="232"
          height="32"
          className="mt-[24px] mb-[24px] ml-[105px] text-white text-4xl uppercase"
        />
    );

    return (
        <div className="z-50 fixed flex flex-row justify-between w-screen h-20 bg-black-rgba-nav">
          {process.env.NODE_ENV === "development" ? (
              <DevDateSetter>{logo}</DevDateSetter>
          ) : (
              <Link href="/" passHref>
                <a>{logo}</a>
              </Link>
          )}
          <nav className={`flex flex-1 ${activeShow ? 'pl-4 2xl:pl-24' : 'pl-28 2xl:pl-48'}  justify-end`}>
            {NAVIGATION.map((item) => {
                return (
                    <NavItem
                      key={item.label}
                      label={item.label}
                      icon={item.icon}
                      className={item.className}
                      url={item.url}
                      active={router.pathname === '/' ? '' : router.pathname.includes(item.pathname) || item.pathname.includes(router.pathname)}
                    />
                );
            })}
            <div className="relative more-menu-container">
              <button
                onClick={() => setMoreVisible(!moreVisible)}
                className={`flex text-white uppercase text-base tracking-wide w-fit py-6 px-4 whitespace-nowrap font-cooper-hewitt-bold`}
              >
                MEHR
                <div className="w-6 h-6 m-1">{SvgTriangleIconDown()}</div>
              </button>
              {moreVisible ? (
                  <div className="absolute bg-black-rgba-nav w-44 right-0 px-4">
                    {MORE_NAVIGATION.map((item) => {
                        return (
                            <NavItem
                              key={item.label}
                              label={item.label}
                              icon={item.icon}
                              fullWidth={true}
                              className={item.className}
                              url={item.url}
                              active={router.pathname === '/' ? '' : router.pathname.includes(item.pathname) || item.pathname.includes(router.pathname)}
                            />
                        );
                    })}
                  </div>
              ) : null
              }
            </div>
          </nav>
          {activeShow ? (
              <RunningShowContainer onClick={() => setAgendaVisible(!agendaVisible)}/>
          ) : (
              <CodeEinlosen style="px-6 my-6 mr-8"/>
          )}
        </div>
    );
}
