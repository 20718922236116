import Link from "next/link";

export default function NavItem({label, icon, url, active, className, onClick, fullWidth=false}) {

    return (
        <Link href={url} passHref>
            <a onClick={onClick} className={`${className} md:mr-12 flex py-6 ${active ? 'border-b-4' : 'hover:border-b-4 h-[80px]'} border-b-white ${fullWidth ? 'w-full' : 'max-w-max'}`}>
                {icon ? (
                    <div className="mr-3 w-6 h-6 mt-1">
                        {icon}
                    </div>
                ) : null}
                <div
                    className={`font-cooper-hewitt-bold text-white uppercase text-base tracking-wide w-fit h-4 sm:h-6 md:h-8 whitespace-nowrap`}>
                    {label}
                </div>
            </a>
        </Link>
    );
}
