import {useRouter} from "next/router";

export default function CodeEinlosen({icon, style}) {
    const router = useRouter();
    const handleClick = () => router.push('/ticket-validation');

    return (
        <div
            className={`${style} bg-gold rounded-full w-max h-8 pt-1 inline-flex justify-center items-center cursor-pointer`}
            onClick={handleClick}>
            <a className="text-white uppercase text-sm font-bold">
                <h4>Code einlösen</h4></a>
        </div>
    );
}
