import { useState } from "react";
import { DateTime, Duration } from "luxon";
import { useEffect } from "react";
import { activeTimeTableItem, nextUpcomingTimeTableItem, toTime } from "/utils";

const useCurrentItem = (activeShow) => {
  const [currentItem, setCurrentItem] = useState();
  const [nextItem, setNextItem] = useState();

  useEffect(() => {
    if (!activeShow) {
      return;
    }

    let ms = -1;
    const updateItem = () => {
      let item = activeTimeTableItem(activeShow.timetable);
      if (item) {
        setCurrentItem(item);
        setNextItem(undefined);
        ms = DateTime.fromISO(item.end_date) - DateTime.now();
      } else {
        item = nextUpcomingTimeTableItem(activeShow.timetable);
        if (item) {
          setCurrentItem(undefined);
          setNextItem(item);
          ms = DateTime.fromISO(item.start_date) - DateTime.now();
        } else {
          // No more items
          setCurrentItem(false);
          setNextItem(false);
        }
      }
    };
    updateItem();

    if (ms >= 0) {
      const timeout = setTimeout(updateItem, ms);

      return () => {
        clearTimeout(timeout);
      };
    }
  });

  return { currentItem, nextItem };
};

export default useCurrentItem;
