import RunningShowNavItem from "./RunningShowNavItem";
import RunningShowAgenda from "./RunningShowAgenda";
import useOutsideClick from "/hooks/useOutsideClick";
import {useContext, useRef} from "react";
import AppContext from "../../context/AppContext";

export default function RunningShowContainer({ onClick }) {
    const {setAgendaVisible} = useContext(AppContext);
    const ref = useRef();

    useOutsideClick(ref, () => {
        setAgendaVisible(false);
    });
    return (
        <div ref={ref}>
          <RunningShowNavItem onClick={onClick}/>
          <RunningShowAgenda/>
        </div>
    );
}
