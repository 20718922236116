import { DateTime, Duration } from "luxon";
import { useEffect, useState } from "react";
import Alarm1 from "/public/assets/icons/Alarm1";

const fullUnits = [
  "years",
  "months",
  "days",
  "hours",
  "minutes",
  "seconds",
  "milliseconds",
];
Duration.prototype.toFull = function () {
  return this.shiftTo.apply(this, fullUnits);
};

export default function CountdownLabel({ nextShowDate }) {
  const [now, setNow] = useState();
  let ms = nextShowDate - now;
  let duration = Duration.fromObject({ hours: 1, minutes: 10 });
  if (!isNaN(ms)) {
    duration = Duration.fromMillis(ms).toFull();
  }

  useEffect(() => {
    let ms = nextShowDate - DateTime.now();
    let duration = Duration.fromMillis(ms).toFull();

    // Init the first time
    if (!now) {
      setNow(DateTime.now());
    }
    if (ms > 0) {
      const timeout = setTimeout(() => {
        setNow(DateTime.now());
      }, duration.get("seconds") * 1000 + duration.get("milliseconds"));

      return () => {
        clearTimeout(timeout);
      };
    }
    // Gets called on every render, so the timeout has max accuracy (JS timeout drift)
  });

  return (
    <div className="bg-gold flex justify-center items-center h-8 rounded-full px-3">
      <div className="uppercase text-white text-sm w-fit font-bold">
        {ms < 60000
          ? "noch 1MIN"
          : `IN ${duration.days ? `${duration.days}T ` : ""}${
              duration.hours
            }H ${duration.minutes}MIN`}
      </div>
      <Alarm1 fill="#fff" size={15} />
    </div>
  );
}
