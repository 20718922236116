import {filter, find, first, groupBy, includes, last, orderBy, sortBy, concat, slice} from "lodash";
import {DateTime, Duration} from "luxon";
import Fuse from "fuse.js";

export function isSubset(superObj, subObj) {
    return Object.keys(subObj).every((ele) => {
        if (typeof subObj[ele] == "object") {
            return isSubset(superObj[ele], subObj[ele]);
        }
        return subObj[ele] === superObj[ele];
    });
}

export function pastMsgForItem(item) {
    if (!item || !includes(['STREAM', 'PRETALK', 'FOLLOWUPTALK'], item.category)) {
        return '';
    }
    
    return {
        STREAM: 'Ihre Vorstellung ist vorbei.',
        PRETALK: 'Ihr Vorgespräch ist vorbei.',
        FOLLOWUPTALK: 'Ihr Nachgespräch ist vorbei.'
    }[item.category];
}

export function activeMsgForItem(item) {
    if (!item || !includes(['STREAM', 'PRETALK', 'FOLLOWUPTALK'], item.category)) {
        return '';
    }
    
    return {
        STREAM: 'Ihre Vorstellung läuft.',
        PRETALK: 'Ihr Vorgespräch läuft.',
        FOLLOWUPTALK: 'Ihr Nachgespräch läuft.'
    }[item.category];
}

export function upcomingMsgForItem(item) {
    if (!item) {
        return '';
    }
    if (item.category === 'STREAM') {
        return `Um ${toTime(item.start_date)} geht Ihre Vorstellung los.`;
    }

    if (item.category === 'PRETALK') {
        return `Um ${toTime(item.start_date)} geht Ihr Vorgespräch los.`;
    }

    if (item.category === 'FOLLOWUPTALK') {
        return `Um ${toTime(item.start_date)} geht Ihr Nachgespräch los.`;
    }
    
    return '';
}


export const handleScroll = ((e) => {
    const sections = document.querySelectorAll("section[id]");
    const element = document.getElementById("scroll-container");
    let scrollY = e.target.scrollTop;

    sections.forEach(current => {
        const sectionHeight = current.offsetHeight;
        const sectionTop = current.offsetTop - 100;
        const sectionId = current.getAttribute("id");

        if (
            scrollY > sectionTop &&
            scrollY <= sectionTop + sectionHeight
        ) {
            document.querySelector(".navChild a[href*=" + sectionId + "]")?.classList.add("active");
            document.querySelector(".navChild nav a.border-b-gold")?.classList.add('border-b-transparent');
            document.querySelector(".navChild nav a.border-b-gold")?.classList.remove('border-b-gold');
        } else if (element.offsetHeight + element.scrollTop === element.scrollHeight) {
            document.querySelector(".navChild nav a:nth-last-child(2)")?.classList.remove("active");
            document.querySelector(".navChild nav a:nth-last-child(1)")?.classList.add("active");
        } else {
            document.querySelector(".navChild a[href*=" + sectionId + "]")?.classList.remove("active");
        }
    });
});

export function flattenProductionsToShows(productions) {
    let shows = [];
    for (let production of productions) {
        for (let show of production.subevents) {
            show.event = {
                title: production.title,
                slug: production.slug,
                poster: production.poster || null,
                picture: production.picture || null
            };
            shows.push(show);
        }
    }
    return shows;
}

export function filterVODShows(shows) {
    return filter(shows, p => includes(p.tags, 'VOD'));
}

export function filterHighlights(productions) {
    // Order by highlighting
    const productionsOrdered = orderBy(productions, 'age_restriction');
    let productionsFiltered = filter(productionsOrdered, p => includes(p.tags, 'highlight'));
    // If less than 4, take first missing productions from non-highlighted productions.
    if (productionsFiltered.length < 4) {
        productionsFiltered = concat(productionsFiltered, slice(
            // Productions not including highlights
            filter(productionsOrdered, p => !includes(p.tags, 'highlight'), 0, 4 - productionsFiltered.length)
        ));
    }
    return productionsFiltered;
}

export function groupShowsByMonth(shows) {
    let showsSorted = sortBy(shows, 'start_date');
    return groupBy(showsSorted, (show) =>
        DateTime.fromISO(show.start_date).toFormat("yyyy-MM")
    );
}

export function groupShowsByDay(shows) {
    let showsSorted = sortBy(shows, 'start_date');
    return groupBy(showsSorted, (show) =>
        DateTime.fromISO(show.start_date).toFormat("yyyy-MM-dd")
    );
}

export function toTime(time) {
    return formatTime(DateTime.fromISO(time));
}

export function formatCountdown(time) {
    if (!time || !time.isValid) {
        return null;
    }
    let ms = time - DateTime.now();
    if (isNaN(ms)) {
        return null;
    }

    let duration = Duration.fromMillis(ms).toFull();

    if (duration.days) {
        return formatRelativeCalendar(time);
    }

    if (ms < 60000) {
        return "< 1MIN";
    }

    return `IN ${duration.hours}H ${duration.minutes}MIN`;
}

export function formatTime(time) {
    if (!time || !time.isValid) {
        return "";
    }

    return time.toFormat("HH:mm 'Uhr'");
}

export function filterProductions(productions, searchQuery) {
    const searchText = searchQuery.trim().replace(/" "/g, "");
    if (searchText) {
        const options = {
            keys: ["title"],
            threshold: 0.3,
        };

        const fuse = new Fuse(productions, options);
        productions = fuse.search(searchText).map((item) => item.item);
    }
    return productions;
}

export function formatRelativeCalendar(time) {
    if (!time || !time.isValid) {
        return "";
    }
    const now = DateTime.now();
    const duration = now - time;

    if (duration.days < 5) {
        return `${time.toRelativeCalendar({now: DateTime.now()})}, ${time
            .setLocale("de")
            .toFormat("ccc dd.MM")}`;
    }
    return `${time.setLocale("de").toFormat("ccc dd.MM")}`;
}

export function mapProduction(production) {
    // TODO: Maybe clone actual production, but without it's faster right now.
    const poster =
        find(production.images, (image) => includes(image.tags, "poster")) || null;
    if (poster) {
        production.poster = poster.url;
    }
    return production;
}

export function activeTimeTableItem(timetable) {
    const now = DateTime.now();
    return first(
        orderBy(
            filter(
                timetable,
                (item) =>
                    now > DateTime.fromISO(item.start_date) &&
                    now < DateTime.fromISO(item.end_date)
            ),
            "start_date"
        )
    );
}

export function nextUpcomingTimeTableItem(timetable) {
    const now = DateTime.now();
    return first(
        orderBy(
            filter(timetable, (item) => now < DateTime.fromISO(item.start_date)),
            "start_date"
        )
    );
}

export function lastPastTimeTableItem(timetable) {
    const now = DateTime.now();
    return last(
        orderBy(
            timetable.filter((item) => now > DateTime.fromISO(item.end_date)),
            "end_date"
        )
    );
}

export function timetableForCategories(timetable, categories) {
    return filter(timetable, (item) => includes(categories, item.category));
}

export function activeTimeTableItemForCategories(timetable, categories) {
    return activeTimeTableItem(timetableForCategories(timetable, categories));
}

export function nextUpcomingTimeTableItemForCategories(timetable, categories) {
    return nextUpcomingTimeTableItem(
        timetableForCategories(timetable, categories)
    );
}

export function lastPastTimeTableItemForCategories(timetable, categories) {
    return lastPastTimeTableItem(timetableForCategories(timetable, categories));
}
