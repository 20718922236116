import * as React from "react";

const SvgAlarm1 = (props) => (
        <svg
    width={props.size || 20}
    height={props.size || 20}
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
        >
        <g>
        <path
    d="M18.213 15.402a10.71 10.71 0 0 1-1.545-5.407V6.672a6.666 6.666 0 1 0-13.334 0V9.99a10.71 10.71 0 0 1-1.546 5.412.833.833 0 0 0 .713 1.265h15a.833.833 0 0 0 .712-1.265ZM10.84 19.854a2.502 2.502 0 0 0 1.517-1.516h-4.71a2.5 2.5 0 0 0 3.194 1.516Z"
    fill={props.fill || '#AC8243'}
        />
        </g>
        </svg>
);

export default SvgAlarm1;
