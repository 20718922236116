import Link from "next/link";
import { DateTime } from "luxon";
import useCurrentItem from "../../hooks/useCurrentItem";

function Item({ label, startTime, buttonLabel, url, state, lastElement }) {
  return (
    <Link href={url} passHref>
      <a
        className={`flex flex-row justify-start py-2 items-center ${
          state === "RUNNING" && "hover:opacity-60"
        }`}
      >
        <div
          className={`${
            state === "RUNNING" || state === "PASSED"
              ? "bg-gold"
              : "bg-gold-light"
          } relative w-3 h-3 rounded-full mr-4`}
        >
          {!lastElement && (
            <div
              className={`${
                state === "PASSED" ? "bg-gold" : "bg-gold-light"
              } absolute left-[4px] top-[11px] h-[34px] w-[4px]`}
            ></div>
          )}
        </div>
        <div className="flex-1 font-bold mr-12 text-sm">
          {startTime.toFormat("HH:mm")} - {label}
        </div>
        <button
          className={`${
            state === "RUNNING" ? "" : "bg-opacity-40"
          } bg-gold rounded-full px-4 py-1 bg-gold text-white uppercase text-sm`}
        >
          {buttonLabel}
        </button>
      </a>
    </Link>
  );
}

const CATEGORIES = {
  PRETALK: {
    room: "bar",
    label: "Teilnehmen",
  },
  STREAM: {
    room: "stage",
    label: "zum Stream",
  },
  FOLLOWUPTALK: {
    room: "bar",
    label: "Teilnehmen",
  },
};

export default function Timetable({ show }) {
  const { currentItem, nextItem } = useCurrentItem(show);

  return (
    <div className="px-3">
      {show.timetable.map((item, ix) => {
        const category = CATEGORIES[item.category] || CATEGORIES.STREAM;
        const state = "PASSED";
        const start_date = DateTime.fromISO(item.start_date);
        const end_date = DateTime.fromISO(item.end_date);
        const now = DateTime.now();
        if (start_date < now) {
          if (end_date > now) {
            state = "RUNNING";
          } else {
            state = "PASSED";
          }
        } else {
          state = "NOT_YET_STARTED";
        }

        return (
          <Item
            key={item.title}
            startTime={DateTime.fromISO(item.start_date)}
            label={item.title}
            url={"/" + category.room}
            buttonLabel={category.label}
            state={state}
            lastElement={ix === show.timetable.length - 1}
          />
        );
      })}
    </div>
  );
}
