import {DateTime, Duration} from "luxon";
import {SvgCalendarGrid, SvgEventTicket, SvgGroup, SvgShop} from '/components/shared/SvgIcons'

const LOCALE = 'de';

function nowPlusTime(days, hours, minutes = 1) {
    return DateTime.now().plus(
        Duration.fromObject({days: days, hours: hours, minutes: minutes, seconds: 30})
    ).setLocale(LOCALE);
}

export const events = [
    {
        id: '3fbb0b58-724f-4afe-a922-2800590a3511',
        slug: "der-grueffelo",
        title: "Der Grüffello",
        picture: "/assets/images/posters/grueffelo.jpg",
        shows: [
            {
                id: '46b5e01a-81d0-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(2, 10),
                age_groups: ['children'],
                stream_type: 'LIVESTREAM',
                in_theater: true,
                extras: ['talk']
            },
            {
                id: '6623bc88-81d0-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(6, 10),
                age_groups: ['children'],
                stream_type: 'VOD',
                in_theater: false,
                extras: ['talk']
            },
            {
                id: '6faa82a0-81d0-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(8, 10),
                age_groups: ['children'],
                stream_type: 'VOD',
                in_theater: false,
                extras: ['talk']
            },
        ],
        start_time: DateTime.now().plus(
            Duration.fromObject({hours: 1, minutes: 39, seconds: 30})
        ).setLocale(LOCALE),
        description_short: `Der Grüffelo ist ein modernes Märchen, in dem der Mut der Kleinen über die Angst vor den Großen siegt. 1999 erschienen, wurde der Grüffelo zu einem der erfolgreichsten Kinderbücher Großbritanniens und wird bereits als moderner Klassiker bezeichnet. Die fantasievollen Reime und Einfälle der Autorin Julia Donaldson und die liebevollen Zeichnungen des Illustratoren Alex Scheffler brachten dem Grüffelo mehrere Preise ein. Unter anderem wurde das Buch mit dem renommierten britischen „Nestlé Children's Book Prize" ausgezeichnet, in mehr als 30 Sprachen übersetzt und mehr als 10 Millionen Mal verkauft.`,
        details_html: `<p>DEUTSCHSPRACHIGE ERSTAUFFÜHRUNG</p>
<p>nach dem Bilderbuch von Axel Scheffler und Julia Donaldson</p>
<p>Eine Koproduktion mit Tall Stories, London</p>
<p>&nbsp;</p>
<p>Altersempfehlung: ab 3 Jahren</p>
<p>&nbsp;</p>
<p>Dauer: ca. 60 Min / keine Pause</p>`,
        agenda: [
            {
                room: "bar",
                name: "Vorgespräch",
                type: "TALK_WHERBY",
                room_url: "https://rausgegangen.whereby.com/bjorn-kutzner",
                start_time: new Date(),
                end_time: new Date(),
            },
            {
                room: "stage",
                name: "Stream Theaterstück",
                type: "LIVESTREAM",
                room_url:
                    "https://dringebliebenondemand.akamaized.net/folge-27-grenzgang-live-stream-spiritual-india-heilige-orte--b3f8be18-393a-4fcb-a4b0-1c71b6040580/master.m3u8",
                start_time: new Date(),
                end_time: new Date(),
            },
            {
                room: "bar",
                name: "Nachgespräch",
                type: "TALK_WHERBY",
                room_url: "https://rausgegangen.whereby.com/bjorn-kutzner",
                start_time: new Date(),
                end_time: new Date(),
            },
        ],
        trailer: {
            src: "https://dringebliebenondemand.akamaized.net/folge-27-grenzgang-live-stream-spiritual-india-heilige-orte--b3f8be18-393a-4fcb-a4b0-1c71b6040580/master.m3u8",
            type: "application/x-mpegurl",
            poster:
                "https://www.jt-bonn.de/fileadmin/_processed_/b/f/csm_JTB_Play_Header_16-9_Gruffalo_2e4885f0ef.jpg",
        },
        pictures: [
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_DerGrueffelo_2018_5173_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_DerGrueffelo_2018_5240_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_DerGrueffelo_2018_5143_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_DerGrueffelo_2018_5206_WEB.jpg'}
        ]
    },
    {
        id: '1360ce1d-1d3f-45c7-bb5c-63af7b71ab7b',
        slug: "michel-aus-loenneberga",
        title: "Michel Aus Lönneberga",
        description_short: `Seit Astrid Lindgren den kleinen Michel aus Lönneberga 1963 in ihren Geschichten zum Leben erweckte, haben diese Erzählungen um den kleinen Bauernsohn aus Lindgrens Heimat eine unglaubliche Beliebtheit erlangt. Die Michel-Bücher wurden weltweit mehr als 30 Millionen Mal verkauft, in 53 Sprachen übersetzt, mehrfach verfilmt und erscheinen regelmäßig in neuen Ausgaben und Auflagen. Im November 2019 wurde das Stück mit neuer Musik von Michael Barfuß erstmals am JTB aufgeführt.`,
        details_html: `nach dem Roman von Astrid Lindgren
<p>Inszenierung: Bernard Niemeyer</p>
<p>mit neuen Liedern von Michael Barfuß</p>
<p>&nbsp;</p>
<p>LÄUFT DERZEIT NUR ALS STREAM</p>
<p>nur an den angegebenen Terminen</p>
<p>7,95€ pro Endgerät</p>
<p>&nbsp;</p>
<p>Buchung über den Link im Kalender</p>
<p>&nbsp;</p>
<p>Altersempfehlung: ab 5 Jahren</p>
<p>&nbsp;</p>
<p>Dauer: ca. 65 Min / keine Pause</p>`,
        shows: [
            {
                id: '888b9a84-81d0-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(5, 18),
                age_groups: ['adults'],
                stream_type: 'LIVESTREAM',
                in_theater: true,
                extras: ['talk']
            },
            {
                id: '8c5914f2-81d0-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(8, 10),
                age_groups: ['children'],
                stream_type: 'VOD',
                in_theater: false,
                extras: ['talk']
            },
        ],
        start_time: nowPlusTime(1, 2),
        picture: "/assets/images/posters/michel.jpg",
        trailer: {
            src: "https://dringeblieben.prokapi.com/folge-22-grenzgang-live-stream-tief-in-afrika-eine-epische-r-a4aa33a3-56bf-4492-85fb-217c9b3a4d81/master.m3u8",
            type: "application/x-mpegurl",
            poster:
                "https://www.jt-bonn.de/fileadmin/_processed_/4/3/csm_JTB_Play_Header_16-9_Michel_3d3f5149fb.jpg",
        },
        pictures: [
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_MichelAusLoenneberga02_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_MichelAusLoenneberga04_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_MichelAusLoenneberga01_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_MichelAusLoenneberga05_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_MichelAusLoenneberga03_WEB.jpg'},
        ]
    },
    {
        id: 'c268dca8-d4bd-44ff-976d-3d2924469016',
        slug: "ronja-raubertochter",
        title: "Ronja Räubertochter",
        description_short: `‚Ronja Räubertochter‘ ist vielleicht die schönste und vollkommenste Geschichte von Astrid Lindgren – spannend, berührend und komisch zugleich erzählt sie von der Freundschaft zweier Kinder, die verfeindeten Räuberbanden angehören und die ihre Liebe zueinander gegen den Starrsinn der Erwachsenen durchsetzen müssen. Für das JTB ist ‚Ronja Räubertochter‘ auch das Stück, das in seiner 50jährigen Geschichte am häufigsten inszeniert und gespielt wurde. Im Jubiläumsjahr zum 50. Geburtstag des JTB wird Ronja nun zum fünften Mal neu inszeniert.`,
        details_html: `
<p>nach der Erzählung von Astrid Lindgren</p>
<p>&nbsp;</p>
<p>Vormittagsvorstellungen werden OHNE PAUSE gespielt.</p>
<p>&nbsp;</p>
<p>Altersempfehlung: ab 6 Jahren</p>
<p>&nbsp;</p>
<p>Dauer: ca. 90 Min / mit Pause</p>
`,
        start_time: nowPlusTime(1),
        shows: [
            {
                id: '95f9bfb6-81d0-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(2, 22),
                age_groups: ['children'],
                stream_type: 'LIVESTREAM',
                in_theater: true,
                extras: ['talk']
            },
            {
                id: 'df49bca2-81d0-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(6, 13),
                age_groups: ['children'],
                stream_type: 'VOD',
                in_theater: false,
                extras: ['talk']
            },
            {
                id: 'e3913722-81d0-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(8, 10),
                age_groups: ['children'],
                stream_type: 'VOD',
                in_theater: false,
                extras: ['talk']
            },
        ],
        picture: "/assets/images/posters/ronja.jpg",
        trailer: {
            src: "https://dringeblieben.prokapi.com/folge-17-grenzgang-live-stream-mit-andreas-kieling-im-bann-d-6f0ecd0b-7024-4b55-8ec8-1885d8731ade/master.m3u8",
            type: "application/x-mpegurl",
            poster:
                "https://s3.eu-central-1.amazonaws.com/rausgegangen/r2ZhGiZJTs28fWDL9rhV_grenzgang-live-stream-dringeblieben-stream-1636x920-0009-grenzgang-live-stream-andreas-kieling-sliderjpg",
        },
        pictures: [
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_RonjaRaeubertochter_03_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_RonjaRaeubertochter_02_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_RonjaRaeubertochter_04_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_RonjaRaeubertochter_01_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_RonjaRaeubertochter_05_WEB.jpg'},
        ],
    },
    {
        id: '8bf7c189-e899-451c-b2cf-bd9a2454ae95',
        slug: "geheime-freunde",
        title: "Geheime Freunde",
        description_short: `Vor über zehn Jahren, im Mai 2010, fand die Premiere von 'GEHEIME FREUNDE' im JTB statt. Aufgrund des anhaltenden Erfolges des Stückes, das seitdem rund 60.000 Besucher in über 300 Vorstellungen gesehen haben, wurden die Rollen der Kinder schon zum vierten Mal neu besetzt. Die Inszenierung von JTB-Intendant Moritz Seibert ist damit die am längsten laufende Produktion aller Zeiten am JTB.`,
        details_html: `
<p>nach dem Roman 'Der gelbe Vogel' von Myron Levoy</p>
<p>Bühnenfassung: Rudolf Herfurtner</p>
<p>Inszenierung: Moritz Seibert</p>
<p>&nbsp;</p>
<p>Vormittagsvorstellungen werden OHNE PAUSE gespielt.</p>
<p>&nbsp;</p>
<p>Altersempfehlung: ab 12 Jahren</p>
<p>&nbsp;</p>
<p>Dauer: ca. 100 Min / mit Pause</p>
`,
        shows: [
            {
                id: '158bcdf0-81d1-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(2, 22),
                age_groups: ['children'],
                stream_type: 'LIVESTREAM',
                in_theater: true,
                extras: ['talk']
            },
            {
                id: '1a812abc-81d1-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(6, 13),
                age_groups: ['children'],
                stream_type: 'VOD',
                in_theater: false,
                extras: ['talk']
            },
            {
                id: '1f5863b6-81d1-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(8, 10),
                age_groups: ['children'],
                stream_type: 'VOD',
                in_theater: false,
                extras: ['talk']
            },
        ],
        start_time: nowPlusTime(2, 18),
        picture: "/assets/images/posters/geheime_freunde.jpg",
        trailer: {
            src: "https://dringeblieben.prokapi.com/folge-17-grenzgang-live-stream-mit-andreas-kieling-im-bann-d-6f0ecd0b-7024-4b55-8ec8-1885d8731ade/master.m3u8",
            type: "application/x-mpegurl",
            poster:
                "https://www.jt-bonn.de/fileadmin/_processed_/d/d/csm_JTB_Play_Header_16-9_GeheimeFreunde_cbb2e4f942.jpg",
        },
        pictures: [
            {url: 'https://www.jt-bonn.de/fileadmin/jtb/St%C3%BCcke/Geheime_Freunde/geheime_freunde_prod_1.jpeg'},
            {url: 'https://www.jt-bonn.de/fileadmin/jtb/St%C3%BCcke/Geheime_Freunde/geheime_freunde_prod_2.jpeg'},
            {url: 'https://www.jt-bonn.de/fileadmin/jtb/St%C3%BCcke/Geheime_Freunde/geheime_freunde_prod_3.jpeg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_geheime_Freunde_4855_RGB_72dpi_400.jpg'},
        ]
    },
    {
        id: '9898e4e7-7a8f-4b7a-816e-72911d8ee7c7',
        slug: "surfguards",
        title: "Die Surfguards",
        description_short: `Das Junge Theater Bonn setzt die mit ‚TKKG – Gefangen in der Vergangenheit‘ begonnene digitale Theaterproduktion mit einem neuen Stück fort. ‚Die Surfguards – Nur das eine Leben‘ wurde gemeinsam mit Jugendlichen entwickelt und beschäftigt sich mit Themen, die mit der zunehmenden Bedeutung des Internets in den Lockdowns ebenfalls nochmal an Bedeutung und an Brisanz gewonnen haben: Cybermobbing, Hate Speech, Identity Theft und vielen weiteren Phänomenen, denen Kinder und Jugendliche im Internet und den Sozialen Netzwerken fast zwangsläufig begegnen.`,
        details_html: `
<p>von Moritz Seibert</p>
<p>entwickelt mit Jugendlichen aus dem Nachwuchsensemble</p>
<p>&nbsp;</p>
<p>Die Vorstellungen werden zeitgleich live gestreamt</p>
<p>und können im Theater oder per Stream besucht werden</p>
<p>&nbsp;</p>
<p>Uraufführung: 29. Oktober 2021</p>
<p>&nbsp;</p>
<p>Altersempfehlung: ab 12 Jahren</p>
<p>&nbsp;</p>
<p>Dauer: ca. 85 Minuten / keine Pause</p>
`,
        start_time: nowPlusTime(8, 10),
        shows: [
            {
                id: '270b6a0e-81d1-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(2, 22),
                age_groups: ['children'],
                stream_type: 'LIVESTREAM',
                in_theater: true,
                extras: ['talk']
            },
            {
                id: '2af01962-81d1-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(6, 13),
                age_groups: ['children'],
                stream_type: 'VOD',
                in_theater: false,
                extras: ['talk']
            },
            {
                id: '2f7f5fba-81d1-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(8, 10),
                age_groups: ['children'],
                stream_type: 'VOD',
                in_theater: false,
                extras: ['talk']
            },
        ],
        picture: "/assets/images/posters/surfguards.jpg",
        trailer: {
            src: "https://dringeblieben.prokapi.com/folge-17-grenzgang-live-stream-mit-andreas-kieling-im-bann-d-6f0ecd0b-7024-4b55-8ec8-1885d8731ade/master.m3u8",
            type: "application/x-mpegurl",
            poster:
                "https://www.jt-bonn.de/fileadmin/_processed_/b/3/csm_JTB_DieSurfguards_16-9_sponsors_WEB_2fb630aae5.jpg",
        },
        pictures: [
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_DieSurfguards01_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_DieSurfguards02_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_DieSurfguards04_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_DieSurfguards03_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_DieSurfguards05_WEB.jpg'},
        ],
        material: {
            program: {
                url: 'https://www.jt-bonn.de/fileadmin/Theaterpaedagogik/Materialmappen/JTB_Materialmappe_DieSurfguards.pdf',
                picture: 'https://www.jt-bonn.de/fileadmin/_processed_/a/3/csm_JTB_Materialmappe_DieSurfguards_4e27c14e49.gif'
            }
        }
    },
    {
        id: '859d4788-d704-40bb-a40a-71fbf38e8bfc',
        slug: "das-letzte-aufgebot",
        title: "Das Letzte Aufgebot",
        description_short: `‚Das letzte Aufgebot‘ erzählt die Geschichte einer verlorenen Jugend. Hunderttausende Kinder wurden in den letzten Monaten des ‚Dritten Reiches‘ zum Volkssturm eingezogen, von der SS mit rassistischer Nazipropaganda fanatisiert und mit völlig unzureichender Ausbildung und Ausrüstung den übermächtigen alliierten Truppen entgegengeworfen. Für die Jungen bedeutete das in vielen Fällen den Tod, und die Überlebenden wurden von den Kriegserlebnissen und dem anschließenden Zusammenbruch des Lügengebildes des ‚Dritten Reiches‘, für das sie mit ihrer naiven Begeisterung kämpfen wollten, schwer traumatisiert.`,
        details_html: `
<p>Autor und Inszenierung: Moritz Seibert</p>
<p>Co-Autoren: Oscar Kafsack, Karl Junker, Fabiola Mon de la Fuente</p>
<p>&nbsp;</p>
<p>Vormittagsvorstellungen werden OHNE PAUSE gespielt.</p>
<p>&nbsp;</p>
<p>Altersempfehlung: ab 13 Jahren</p>
<p>&nbsp;</p>
<p>Dauer: ca. 140 Min / mit Pause</p>
`,
        shows: [],
        picture: "/assets/images/posters/letzte_aufgebot.jpg",
        trailer: {
            src: "https://dringeblieben.prokapi.com/folge-17-grenzgang-live-stream-mit-andreas-kieling-im-bann-d-6f0ecd0b-7024-4b55-8ec8-1885d8731ade/master.m3u8",
            type: "application/x-mpegurl",
            poster:
                "https://www.jt-bonn.de/fileadmin/_processed_/a/4/csm_JTB_Play_Header_16-9_DLA_5ff4a8c2c8.jpg",
        },
        pictures: [
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_DasLetzteAufgebot_01_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_DasLetzteAufgebot_02_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_DasLetzteAufgebot_05_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_DasLetzteAufgebot_03_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_DasLetzteAufgebot_04_WEB.jpg'},
        ],
        material: {
            program: {
                url: 'https://www.jt-bonn.de/fileadmin/Theaterpaedagogik/Materialmappen/JTB_Materialmappe_DasLetzteAufgebot.pdf',
                picture: 'https://www.jt-bonn.de/fileadmin/_processed_/6/9/csm_JTB_Materialmappe_DasLetzteAufgebot_d851427ead.gif'
            }
        }
    },
    {
        id: 'e0e8b1db-270b-4f2d-b22a-68ffcdcc4e4d',
        slug: "jim-knopf",
        title: "Jim Knopf",
        description_short: `Michael Endes Abenteuererzählung von Jim Knopf und Lukas dem Lokomotivführer gehört längst zu den beliebtesten und weltweit meistgelesenen Kinderbuchklassikern überhaupt. Sowohl 'Jim Knopf und Lukas der Lokomotivführer' als auch 'Jim Knopf und die Wilde 13' wurden kurz nach ihrer Veröffentlichung preisgekrönt, erhielten zahlreiche internationale Auszeichnungen und wurden in über 25 Sprachen, darunter auch ins Arabische, Japanische und Koreanische, übersetzt. Die Bücher wurden über 3 Millionen Mal verkauft.`,
        details_html: `
<p>nach dem Roman von Michael Ende</p>
<p>Inszenierung: Bernard Niemeyer</p>
<p>&nbsp;</p>
<p>Illustrationen: Mathias Weber nach F.J. Tripp.</p>
<p>©2005 Thienemann in der Thienemann-Esslinger Verlag GmbH</p>
<p>&nbsp;</p>
<p>Vormittagsvorstellungen werden OHNE PAUSE gespielt.</p>
<p>&nbsp;</p>
<p>Altersempfehlung: ab 5 Jahren</p>
<p>&nbsp;</p>
<p>Dauer: ca. 100 Minuten / mit Pause</p>`,
        start_time: nowPlusTime(6, 3),
        shows: [
            {
                id: '34c57d06-81d1-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(2, 22),
                age_groups: ['children'],
                stream_type: 'LIVESTREAM',
                in_theater: true,
                extras: ['talk']
            },
            {
                id: '4852962e-81d1-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(6, 13),
                age_groups: ['children'],
                stream_type: 'VOD',
                in_theater: false,
                extras: ['talk']
            },
            {
                id: '4ce8917a-81d1-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(8, 10),
                age_groups: ['children'],
                stream_type: 'VOD',
                in_theater: false,
                extras: ['talk']
            },
        ],
        picture: "/assets/images/posters/jim_knopf.jpg",
        trailer: {
            src: "https://dringeblieben.prokapi.com/folge-17-grenzgang-live-stream-mit-andreas-kieling-im-bann-d-6f0ecd0b-7024-4b55-8ec8-1885d8731ade/master.m3u8",
            type: "application/x-mpegurl",
            poster:
                "https://www.jt-bonn.de/fileadmin/_processed_/f/a/csm_JTB_Play_Header_16-9_JimKnopf_2933a7706b.jpg",
        },
        pictures: [
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_JimKnopf_04_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_JimKnopf_02_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_JimKnopf_03_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_JimKnopf_01_WEB.jpg'},
        ],
        material: {
            program: {
                url: 'https://www.jt-bonn.de/fileadmin/Theaterpaedagogik/Materialmappen/JTB_Materialmappe_JimKnopf.pdf',
                picture: 'https://www.jt-bonn.de/fileadmin/_processed_/a/8/csm_JTB_Materialmappe_JIimKnopf_cc1a278225.gif'
            }
        }
    },
    {
        id: '879938ac-5d69-462a-89a5-c4550ea164d4',
        slug: "die-schule-der-magischen-tiere",
        title: "Die Schule der magischen Tiere",
        description_short: `Zwölf Bände sind bereits über ‚Die Schule der magischen Tiere‘ erschienen. Die Erzählungen von Margit Auer sind regelmäßig auf den vorderen Plätzen der Bestsellerlisten zu finden und wurden bisher in 13 Sprachen übersetzt. Der Mischung aus Schule, Tieren und Magie kann so leicht kein Kind widerstehen und da ist es nicht verwunderlich, dass von den ersten zwölf Bänden bisher insgesamt knapp sieben Million Exemplare verkauft wurden.`,
        details_html: `
<p>nach den Kinderbuch-Bestsellern von Margit Auer</p>
<p>für die Bühne bearbeitet von Tristan Berger</p>
<p>mit Musik von Danyal Dhondy</p>
<p>Inszenierung von Nick Westbrock</p>
<p>&nbsp;</p>
<p>Vormittagsvorstellungen werden OHNE PAUSE gespielt.</p>
<p>&nbsp;</p>
<p>Altersempfehlung: ab 7 Jahren</p>
<p>&nbsp;</p>
<p>Dauer: ca. 110 Minuten / mit Pause</p>
`,
        start_time: nowPlusTime(25, 2),
        shows: [
            {
                id: '545f8fbc-81d1-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(2, 22),
                age_groups: ['children'],
                stream_type: 'LIVESTREAM',
                in_theater: true,
                extras: ['talk']
            },
            {
                id: '584ec106-81d1-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(6, 13),
                age_groups: ['children'],
                stream_type: 'VOD',
                in_theater: false,
                extras: ['talk']
            },
            {
                id: '5bd992f6-81d1-11ec-a8a3-0242ac120002',
                start_time: nowPlusTime(8, 10),
                age_groups: ['children'],
                stream_type: 'VOD',
                in_theater: false,
                extras: ['talk']
            },
        ],
        picture: "/assets/images/posters/magische_tiere.jpg",
        trailer: {
            src: "https://dringeblieben.prokapi.com/folge-17-grenzgang-live-stream-mit-andreas-kieling-im-bann-d-6f0ecd0b-7024-4b55-8ec8-1885d8731ade/master.m3u8",
            type: "application/x-mpegurl",
            poster:
                "https://www.jt-bonn.de/fileadmin/_processed_/d/5/csm_JTB_DieSchuleDerMagischenTiere_01_WEB_711dd63dc7.jpg",
        },
        pictures: [
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_DieSchuleDerMagischenTiere_03_WEB.jpg'},
            {url: 'https://www.jt-bonn.de/fileadmin/user_upload/JTB_DieSchuleDerMagischenTiere_02_WEB.jpg'},
        ]
    },
];

export function eventBySlug(slug) {
    for (let event of events) {
        if (event.slug === slug) {
            return event;
        }
    }
}

export function getCurrentShow() {
    return events[0];
}

export const NAVIGATION = [
    {
        label: "Foyer",
        pathname: "/foyer",
        url: "/foyer",
        className: "foyer",
        icon: SvgGroup("#fff"),
    },
    {
        label: "Spielplan",
        pathname: "/schedule",
        url: "/schedule",
        className: "schedule",
        icon: SvgCalendarGrid('#fff'),
    },
    {
        label: "Alle Stücke",
        pathname: "/productions",
        url: "/productions",
        className: "productions",
        icon: SvgEventTicket(),
    },
    {
        label: "Über Uns",
        pathname: "/about-theater/[...slug].js",
        url: "/about-theater#theater",
        className: "about-theater",
        icon: SvgGroup('#fff'),
    },
    {
        label: "Shop",
        pathname: "/shop",
        url: "/shop",
        className: "shop",
        icon: SvgShop(),
    },
];

export const MORE_NAVIGATION = [
    {
        label: "Foyer",
        pathname: "/foyer",
        url: "/foyer",
        className: "foyer-more-menu",
        icon: SvgGroup("#fff"),
    },
    {
        label: "Spielplan",
        pathname: "/schedule",
        url: "/schedule",
        className: "schedule-more-menu",
        icon: SvgCalendarGrid('#fff'),
    },
    {
        label: "Alle Stücke",
        pathname: "/productions",
        url: "/productions",
        className: "productions-more-menu",
        icon: SvgEventTicket(),
    },
    {
        label: "Über Uns",
        pathname: "/about-theater/[...slug].js",
        url: "/about-theater#theater",
        className: "about-theater-more-menu",
        icon: SvgGroup('#fff'),
    },
    {
        label: "Shop",
        pathname: "/shop",
        url: "/shop",
        className: "shop-more-menu",
        icon: SvgShop(),
    },
];

export const NAVIGATION_MOBILE = [
    {
        label: "Foyer",
        pathname: "/foyer",
        url: "/foyer",
        icon: SvgGroup("#fff"),
    },
    {
        label: "Spielplan",
        pathname: "/schedule",
        url: "/schedule",
        icon: SvgCalendarGrid('#fff'),
    },
    {
        label: "Alle Stücke",
        pathname: "/productions",
        url: "/productions",
        icon: SvgEventTicket(),
    },
    {
        label: "Über Uns",
        pathname: "/about-theater/[...slug].js",
        url: "/about-theater#theater",
        icon: SvgGroup('#fff'),
    },
    {
        label: "Shop",
        pathname: "/shop",
        url: "/shop",
        icon: SvgShop(),
    },

];

export const THEATERDETAILS_NAVIGATION = [
    {
        label: "Galerie",
        url: {nav: "gallerie"},
    },
    {
        label: "Inhalt",
        url: {nav: "description"},
    },
    {
        label: "Termine",
        url: {nav: "tickets"},
    },
    {
        label: "Für Schulen",
        url: {nav: "for-schools"},
    },
    {
        label: "Zusatzmaterial",
        url: {nav: "additional-material"},
    },
];

export const ABOUT_THEATER_NAVIGATION = [
    {
        label: "Das Theater",
        url: {nav: "theater"},
    },
    {
        label: "Schauspielschule",
        url: {nav: "schauspielschule"},
    },
    {
        label: "Stage Door ",
        url: {nav: "stage-door"},
    },
    {
        label: "Partner und Sponsoren",
        url: {nav: "sponsors"},
    },
];

export const STAGE_DOOR_NAVIGATION = [
    {
        label: "Überblick",
        url: {nav: "overview"},
    },
    {
        label: "Was macht eigentlich...",
        url: {nav: "whatabout"},
    },
    {
        label: "Tutorials",
        url: {nav: "tutorials"},
    }
];

export const categories = [
    {
        label: "Alle",
    },
    {
        label: "Für Kinder",
    },
    {
        label: "Für Erwachsene",
    },
    {
        label: "Live Stream",
    },
    {
        label: "Video on Demand",
    },
    {
        label: "Live im JTB",
    },
    {
        label: "Mit Vorgespräch",
    },
];

export const CATEGORIES_FOR_ROOM = {
    stage: ['STREAM'],
    bar: ['PRETALK', 'FOLLOWUPTALK']
};

export const ROOM_FOR_CATEGORY = {
    STREAM: 'stage',
    PRETALK: 'bar',
    FOLLOWUPTALK: 'stage'
};

export const LABEL_FOR_CATEGORY = {
    STREAM: 'zur Vorstellung',
    PRETALK: 'zum Vorgespräch',
    FOLLOWUPTALK: 'zum Nachgespräch'
};


// < 1 Min
// Derzeit läuft Vorführung
// ZUM Vorführung????

// Was bedeutet dieses <1 Min ?

// Der Text darunter sollte lauten
// IHRE VORSTELLUNG LÄUFT
// ZUR VORSTELLUNG


