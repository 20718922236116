export default function Logo({name, className, width, height}) {
    return (
        <div className={className}>
            <svg width={width} height={height} viewBox="0 0 243 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.53366 0.421265H7.01677V26.5118C7.01677 30.2332 5.36782 31.8833 2.68391 31.8833C1.8018 31.8833 0.882116 31.8444 0 31.6526V28.0076C0.422276 28.0076 1.53366 27.8158 1.53366 26.6258V0.421265Z"
                    fill="white"/>
                <path
                    d="M16.1421 0.421265H21.6252V27.0108C21.6252 30.7323 20.3596 31.9987 17.2923 31.9987H13.5346C10.3908 31.9987 8.93359 30.5793 8.93359 27.0108V0.421265H14.3778V27.0108C14.3778 27.6252 14.8377 27.97 15.26 27.97C15.6822 27.97 16.1421 27.6252 16.1421 27.0108V0.421265Z"
                    fill="white"/>
                <path
                    d="M28.449 14.9637V31.6539H23.541V0.421265H29.177L32.5124 17.188V0.421265H37.5733V31.6539H31.7067L28.449 14.9637Z"
                    fill="white"/>
                <path
                    d="M52.1818 27.0121C52.1818 30.7336 50.9163 32 47.849 32H44.0912C40.9475 32 39.4902 30.5806 39.4902 27.0121V4.98789C39.4902 1.26642 40.7558 0 43.7842 0H47.3114C50.494 0 51.9513 1.38178 51.9513 4.98789V10.5513H46.6987V4.98789C46.6987 4.33589 46.2764 3.98979 45.8555 3.98979C45.4332 3.98979 44.9358 4.33459 44.9358 4.98789V27.0121C44.9358 27.6265 45.3956 27.9713 45.8179 27.9713C46.2402 27.9713 46.7 27.6265 46.7 27.0121V20.4895H45.7415V16.2301H52.1831V27.0121H52.1818Z"
                    fill="white"/>
                <path
                    d="M63.4541 31.6539H53.9062V0.421265H63.4541V4.64179H59.3518V13.6584H63.0707V17.8789H59.3518V27.4334H63.4541V31.6539Z"
                    fill="white"/>
                <path
                    d="M72.1184 21.6016C72.1184 17.9567 64.9863 15.9993 64.9863 10.8196V4.949C64.9863 1.26512 66.2894 0 69.3192 0H72.7699C75.9525 0 77.2181 1.38178 77.2181 4.949V10.5889H72.1184V4.91011C72.1184 4.2957 71.735 3.95091 71.2751 3.95091C70.7764 3.95091 70.393 4.2957 70.393 4.91011V9.898C70.393 13.4276 77.4098 14.7329 77.4098 20.5258V27.0095C77.4098 30.6934 76.1067 31.9974 73.0769 31.9974H69.5886C66.4449 31.9974 65.1793 30.578 65.1793 27.0095V20.4869H70.3943V27.0484C70.3943 27.6628 70.7389 28.0076 71.2376 28.0076C71.7363 28.0076 72.1197 27.6628 72.1197 27.0484V21.6016H72.1184Z"
                    fill="white"/>
                <path d="M88.4889 4.64179H85.498V0.421265H96.9629V4.64179H93.972V31.6539H88.4889V4.64179Z"
                      fill="white"/>
                <path
                    d="M105.513 17.8802H103.749V31.6552H98.3047V0.421265H103.749V13.6584H105.513V0.421265H110.996V31.6539H105.513V17.8802Z"
                    fill="white"/>
                <path
                    d="M122.46 31.6539H112.912V0.421265H122.46V4.64179H118.358V13.6584H122.077V17.8789H118.358V27.4334H122.46V31.6539Z"
                    fill="white"/>
                <path
                    d="M126.333 0.421265H134.576L137.567 31.6539H132.352L131.738 23.7884H129.054L128.479 31.6539H123.227L126.333 0.421265ZM129.363 19.5679H131.432L130.397 5.79284L129.363 19.5679Z"
                    fill="white"/>
                <path d="M140.366 4.64179H137.375V0.421265H148.84V4.64179H145.849V31.6539H140.366V4.64179Z"
                      fill="white"/>
                <path
                    d="M159.728 31.6539H150.18V0.421265H159.728V4.64179H155.625V13.6584H159.344V17.8789H155.625V27.4334H159.728V31.6539Z"
                    fill="white"/>
                <path
                    d="M173.953 31.6539H168.47V20.2588C168.47 19.6444 168.01 19.2996 167.588 19.2996H166.706V31.6539H161.262V0.421265H169.313C172.457 0.421265 173.953 1.80305 173.953 5.40916V12.3155C173.953 15.4614 173.034 16.8432 170.925 17.2269C173.034 17.5717 173.953 18.9924 173.953 22.0994V31.6539ZM168.47 5.40916C168.47 4.75715 168.048 4.41106 167.627 4.41106H166.707V15.2307H167.589C168.012 15.2307 168.471 14.8859 168.471 14.2715V5.40916H168.47Z"
                    fill="white"/>
                <path
                    d="M195.422 26.666C195.422 30.2721 193.927 31.6539 190.782 31.6539H182.73V0.421265H190.782C193.926 0.421265 195.422 1.80305 195.422 5.40916V10.5513C195.422 13.6972 194.502 15.079 192.394 15.4627C194.502 15.8075 195.422 17.2282 195.422 20.3352V26.666ZM189.939 5.40916C189.939 4.75715 189.517 4.41106 189.096 4.41106H188.176V13.4665H189.058C189.48 13.4665 189.94 13.1217 189.94 12.5073V5.40916H189.939ZM189.939 18.4933C189.939 17.8789 189.479 17.5341 189.057 17.5341H188.175V27.6641H189.094C189.517 27.6641 189.938 27.3193 189.938 26.666V18.4933H189.939Z"
                    fill="white"/>
                <path
                    d="M210.336 27.0121C210.336 30.7336 209.071 32 206.003 32H202.246C199.102 32 197.645 30.5806 197.645 27.0121V4.98789C197.645 1.26642 198.91 0 201.939 0H205.696C208.84 0 210.336 1.38178 210.336 4.98789V27.0121ZM204.853 4.98789C204.853 4.33589 204.431 3.98979 204.01 3.98979C203.589 3.98979 203.09 4.33459 203.09 4.98789V27.0121C203.09 27.6265 203.55 27.9713 203.972 27.9713C204.394 27.9713 204.854 27.6265 204.854 27.0121V4.98789H204.853Z"
                    fill="white"/>
                <path
                    d="M217.162 14.9637V31.6539H212.254V0.421265H217.89L221.225 17.188V0.421265H226.286V31.6539H220.42L217.162 14.9637Z"
                    fill="white"/>
                <path
                    d="M233.111 14.9637V31.6539H228.203V0.421265H233.839L237.175 17.188V0.421265H242.235V31.6539H236.369L233.111 14.9637Z"
                    fill="white"/>
            </svg>
          <div className="pl-7 xl:pl-0 font-bold text-sm text-white opacity-70">ONLINE ERLEBEN</div>
        </div>
    );
}
