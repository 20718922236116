import CountdownLabel from "/helpers/CountdownLabel.jsx";
import { useContext, useEffect } from "react";
import AppContext from "/context/AppContext";
import { DateTime } from "luxon";
import { SvgTriangleSmDown1 } from "/components/shared/SvgIcons";
import { toTime, lastPastTimeTableItem } from "/utils";
import useCurrentItem from "../../hooks/useCurrentItem.js";

export default function RunningShowNavItem({ onClick }) {
  const { activeShow, agendaVisible, ticketLogout } = useContext(AppContext);
  const { currentItem, nextItem } = useCurrentItem(activeShow);

  if (!activeShow) {
    return null;
  }

  let msg = "";
  if (currentItem) {
    msg = `Derzeit läuft ${currentItem.title}`;
  } else {
    if (nextItem) {
      msg = `Um ${toTime(nextItem.start_date)} geht's los mit ${
        nextItem.title
      }`;
    } else {
      msg = `Die Veranstaltung ist leider vorbei.`;
    }
  }

  useEffect(() => {
    // Logout 2h after last item's end.
    let to;
    if (currentItem === false && nextItem === false) {
      // Get the last item that ran, so we can add 2h to that.
      const lastItem = lastPastTimeTableItem(activeShow.timetable);
      if (lastItem) {
        const ms =
          DateTime.fromISO(lastItem.end_date).plus({ hours: 2 }) -
          DateTime.now();
        to = setTimeout(
          () => {
            ticketLogout();
          },
          ms < 0 ? 0 : ms
        );
      }
    }

    return () => {
      clearTimeout(to);
    };
  }, [currentItem, nextItem]);

  return (
    <div
      className="flex flex-row items-start overflow-hidden w-full cursor-pointer"
      onClick={onClick}
    >
      <div
        className="w-[60px] h-[80px] bg-center bg-cover"
        style={{
          backgroundImage: `url(${
            activeShow.event.poster || activeShow.event.picture
          })`,
        }}
      ></div>
      <div className="flex flex-col items-start mx-6">
        <div className="text-white text-sm my-1 truncate w-48">
          Ihre Vorstellung {activeShow.event.title}
        </div>
        {currentItem ? (
          <div className="bg-gold flex justify-center items-center h-8 rounded-full px-4 font-bold uppercase text-white text-sm w-fit">
            Live
            <div className="bg-white h-3 w-3 rounded-full ml-2 "></div>
          </div>
        ) : nextItem ? (
          <CountdownLabel
            nextShowDate={DateTime.fromISO(nextItem.start_date)}
          />
        ) : (
          <div className="text-white">Die Show ist vorbei</div>
        )}
      </div>
      <div
        className={`${
          agendaVisible ? "rotate-x-180" : ""
        } invisible md:visible text-xl font-black text-white mt-6 mx-1`}
      >
        {<SvgTriangleSmDown1 />}
      </div>
    </div>
  );
}
