import {useRouter} from 'next/router';
import Link from 'next/link';
import {useContext} from 'react';
import AppContext from '/context/AppContext';
import Timetable from '/components/shared/Timetable';
import {AnimatePresence, motion} from 'framer-motion';
import {SvgFurnishedProperty, SvgLogout, SvgMinimalUp} from '/components/shared/SvgIcons';

function Item({label, url, onClick}) {
    return <div onClick={onClick} className="w-1/2 text-gold uppercase p-1 cursor-pointer">{label}</div>;
}

export default function RunningShowAgenda() {
    const router = useRouter();
    const {activeShow, activeRedemption, ticketLogout, agendaVisible, setAgendaVisible} = useContext(AppContext);

    const nextShowDate = 'IN 1H 59MIN';

    if (!activeShow || !agendaVisible) {
        return <AnimatePresence>null</AnimatePresence>;
    }

    function onLogoutOfShow() {
        ticketLogout(null);
        router.push('/foyer');
    }

    function navigateTo(url) {
        router.push(url);
    }

    return (
        <AnimatePresence initial={false}>
            <motion.div
                layoutId={'RunningShowAgenda'}
                key={'RunningShowAgenda'}
                initial={{opacity: 1, translateY: -400}}
                animate={{opacity: 1, translateY: 0}}
                exit={{opacity: 1, translateY: -400}}
                transition={{default: {duration: 0.6}}}
                className="fixed w-[90vw] md:w-96 xl:w-fit flex flex-col items-start bg-gray-light padding-0 2xl:top-[80px] right-0 py-3 rounded-b-lg -z-40">
                <Link href="/program">
                    <a className="text-black font-bold text-lg mb-3 px-3">Programm</a>
                </Link>
                <Timetable show={activeShow}/>
                {activeRedemption?.ticket?.conference_url && (
                    <Link href="/private-room">
                        <a className='bg-gold flex p-4 w-full'>
                            <SvgFurnishedProperty/>
                            <div className='uppercase text-white text-sm leading-5 ml-4'>Zum persönlichen Raum
                            </div>
                        </a>
                    </Link>
                )}
              <div className="w-full px-3">
                <div className="flex flex-row w-full justify-between">
                  {activeShow.event?.products.length ? (
                      <Item
                        onClick={() => navigateTo(`/theater-details/${activeShow.event.slug}#additional-material`)}
                        label="Programmheft"/>) : null}
                  <Item
                    onClick={() => navigateTo(`/shop`)}
                    label="Zum Shop"/>
                </div>
                <div className="flex flex-row w-full justify-between">
                  {activeShow.event?.products.length ? (

                      <Item
                        onClick={() => navigateTo(`/theater-details/${activeShow.event.slug}#additional-material`)}
                        label="Zusatzmaterial"/>) : null}
                  {/* <Item label="Making Of" */}
                  {/*       onClick={() => navigateTo(`/theater-details/${activeShow.event.slug}#additional-material`)}/> */}
                </div>
                  <div className='flex hover:scale-105 transition-transform place-content-center text-gold uppercase p-1 cursor-pointer mt-4'
                       onClick={onLogoutOfShow}>
                    <SvgLogout className='mr-2'/>
                    Theater Verlassen
                  </div>
                  <div className='cursor-pointer hover:scale-125 transition-transform flex justify-center mt-4' onClick={() => setAgendaVisible(!agendaVisible)}>
                    {SvgMinimalUp()}
                  </div>
                </div>
            </motion.div>
        </AnimatePresence>
    );
}
