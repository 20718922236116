import {useRouter} from "next/router";
import {useState} from "react";
import NavBarMobile from "/components/shared/navigation/NavBarMobile";
import NavBarDesktop from "/components/shared/navigation/NavBarDesktop";
import Logo from "/components/shared/Logo";
import Link from "next/link";
import CodeEinlosen from "/components/shared/buttons/CodeEinlosen";
import {SvgHamburgerIcon, SvgSimpleRemove1} from "/components/shared/SvgIcons";

export default function NavBar() {
    const router = useRouter();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div>
            <div className='hidden xl:block'><NavBarDesktop/></div>
            <div className='flex-col z-50 fixed w-screen'>
                <div
                    className='flex justify-between py-4 px-4 h-24 xl:hidden bg-black-rgba-nav'>
                    <div className='p-2 mt-4'>
                        <Link href="/" passHref>
                            <a>
                                <Logo
                                    width='243'
                                    height='24'
                                />
                            </a>
                        </Link>
                    </div>
                    <div className='mr-4 xl:mr-8 flex justify-center items-center'>
                        <div className='hidden md:block mr-4'>
                            <CodeEinlosen style='px-4'/>
                        </div>
                        <button onClick={() => setIsOpen(!isOpen)}>
                            {
                                !isOpen ? (
                                    SvgHamburgerIcon()
                                ) : (
                                    SvgSimpleRemove1()
                                )
                            }
                        </button>
                    </div>
                </div>
              <div className='bg-gray-dark'>
                {isOpen ? (<NavBarMobile setIsOpen={setIsOpen}/>) : ''}
                </div>
            </div>
        </div>
    );
}

