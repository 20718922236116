import {NAVIGATION_MOBILE} from "../constants";
import NavItem from "./NavItem";
import {useRouter} from "next/router";
import CodeEinlosen from "../buttons/CodeEinlosen";
import RunningShowContainer from "../RunningShowContainer";
import React, {useContext} from "react";
import AppContext from "/context/AppContext";

export default function NavBarMobile({ setIsOpen }) {
    const router = useRouter();
    const {activeShow, setActiveShow, setAgendaVisible} = useContext(AppContext);

    return (
        <div style={{height: 'calc(100vh - 6rem)'}} className="overflow-y-auto">
          {activeShow ?
           <div className='w-full px-10 pt-4'>
             <RunningShowContainer onClick={() => {
                 router.push('/program');
                 setIsOpen(false);
             }}/>
           </div> :
           <div className='pt-4 ml-10 md:hidden'>
             <CodeEinlosen style='px-4'/>
           </div>}
          <div className='mx-10'>
            <div className='bullet'>
              {
                  NAVIGATION_MOBILE.map((item) => {
                      return (
                          <NavItem
                            onClick={() => setIsOpen(false)}
                            key={item.label}
                            label={item.label}
                            fullWidth={true}
                            icon={item.icon}
                            url={item.url}
                            active={router.pathname === '/' ? '' : router.pathname.includes(item.pathname) || item.pathname.includes(router.pathname)}
                          />
                      );
                  })
              }
              <div className='text-white mt-4 sm:mt-24 mb-8'>
                <a href="/impressum" className='block mr-12 hover:underline'>Impressum</a>
                <a className="block hover:underline" href="/datenschutz">Datenschutz</a>
                <a className="block hover:underline" href="https://www.jt-bonn.de/unternehmen/allgemeine-geschaeftsbedingungen/">AGB</a>
                <a className="block hover:underline" href="FAQ">FAQ</a>
              </div>
            </div>
          </div>
        </div>
    );
}
